import { Fees } from '@/legacy-types';

export const getPoliticalAgencyBilling = (props: { uid: string; agencyID: string; name: string }): Fees | undefined => {
	const billingCreation = getNextFirstOfMonthInUnixSeconds();

	const isMessageDigital = props.agencyID === '3490';
	const isDispoJoy = props.agencyID === '3579';

	if (isMessageDigital) {
		return {
			address: {
				street: '2054 University Ave STE 600',
				city: 'Berkeley',
				state: 'CA',
				zipCode: '94704',
				country: 'US',
				raw: '2054 University Ave STE 600, Berkeley, CA 94704',
			},
			chargePerSentMMS: 0.015,
			chargePerReceivedMMS: 0.007,
			chargePerSentSMS: 0.008,
			chargePerReceivedSMS: 0.0035,
			chargePerNumber: 1,
			flatFee: 1,
			billingEmail: 'billing@messagedigital.com',
			ccEmails: ['kghodiwala@messagedigital.com'],
			name: props?.name ?? '',
			currency: 'USD',
			planType: 1,
			billingCreation: billingCreation,
		};
	}

	if (isDispoJoy) {
		return {
			address: {
				street: '2818 Flint St',
				city: 'Bellingham',
				state: 'WA',
				zipCode: '98226',
				country: 'US',
				raw: '2818 Flint St, Bellingham, WA 98226',
			},
			chargePerSentMMS: 0.015,
			chargePerReceivedMMS: 0.007,
			chargePerSentSMS: 0.008,
			chargePerReceivedSMS: 0.0035,
			chargePerNumber: 1,
			flatFee: 1,
			billingEmail: 'ryan@dispojoy.com',
			ccEmails: [],
			name: props?.name ?? '',
			currency: 'USD',
			planType: 1,
			billingCreation: billingCreation,
		};
	}

	return undefined;
};

function getNextFirstOfMonthInUnixSeconds() {
	const currentDate = new Date();
	const currentYear = currentDate.getFullYear();
	const currentMonth = currentDate.getMonth();

	let nextFirstOfMonth;
	if (currentDate.getDate() === 1) {
		nextFirstOfMonth = new Date(currentYear, currentMonth, 1);
	} else {
		nextFirstOfMonth = new Date(currentYear, currentMonth + 1, 1);
	}

	const unixSeconds = Math.floor(nextFirstOfMonth.getTime() / 1000);
	return unixSeconds;
}
