const PublicCSS = `
.coverMain {
	text-align: center;
	background-size: cover;
	background-repeat: no-repeat;
	padding-top: 30px;
	border-top-left-radius: 10px;
	border-top-right-radius: 10px;
	height: 160px;
}

.coverRow {
	background-color: rgba(0, 0, 0, 0.7);
	color: #fff;
	padding: 10px 0px;
	line-height: 80px
}

.coverRow.noBar {
	background-color: unset;
}

.coverLogo {
	width: 60px;
	height: 60px;
	max-height: 100%;
}

.coverLogo.noCoverName {
	width: 100px;
	height: 100px;
}

.clientName {
	color: #000;
	font-size: 22px;
	font-weight: bold;
}

.card {
	background: inherit;
	color: inherit;
	text-align: center;
	font-size: 14px;
}

.card > * {
	padding: 10px;
}

.totalPoints {
	font-weight: bold;
	font-size: 28px;
}

.storeName, .discountTitle, .reviewTitle {
	font-size: 22px;
	font-weight: bold;
}

.clientPhone {}
.clientEmail {}
.clientNotice {}

.dealRow {}
.dealTextCol {
	padding: 0px 8px;
	text-align: left;
}

.wallet .card {}
.discount .card {}
.signup .card {}
.review .card {}
.product .card {}

/* fixes broken date input on safari-based browsers */
.ant-input[type="date"] {
	min-height: 30px;
}
`;

export { PublicCSS };
